import i18n from '@/i18n';

function initForm() {
    return {
        name: '',
        kana_name: '',
        email: '',
        phone_number: '',
        password: '',
        team: null,
        role: null,
        note: '',
        account_id: null,
        chatwork_id: '',
        chatwork_token: '',
        phone_setting_type: [],

        configFieldName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.name'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },

        configFieldPhoneNumber: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.cellphoneNumber'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max20', 'number-hyphens']
        },

        configFieldKanaName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.name_kana'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'katakana', 'max255']
        },

        configFieldEmail: {
            autocomplete: 'new-email',
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.email'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255', 'email']
        },

        configFieldPassword: {
            autocomplete: 'new-password',
            isNumber: false,
            isPassword: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.password'),
            errorCustomMess: '',
            valueSync: '',
            type: 'password',
            typesValidate: ['required', 'max255', 'password']
        },

        configFieldSelectRole: {
            trackBy: 'id',
            label: 'text',
            errorField: i18n.t('pageEmployee.role'),
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        configFieldSelectTeam: {
            trackBy: 'id',
            label: 'value',
            errorField: i18n.t('pageEmployee.team'),
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },

        configFieldAccountID: {
            isNumber: true,
            maxlength: 10,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.accountId'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },

        configFieldChatWorkID: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.chatworkId'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },

        configFieldChatWorkToken: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.tokenUser'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };
}

export { initForm };
