<script>
import {
    masterMethods,
    keyMaster,
    employeeMethods,
    checkPermission,
    checkPermissionName,
    settingPhoneMethods,
    authFackMethods
} from '@/state/helpers';
import { showMessage } from '@/utils/messages.js';
import { InputCheckBox, InputText } from '@/components/Input';
import { PHONE_SETTING_EMPLOYEE, nameRoleFixed } from '@/config/var-common.js';
import { state } from '@/state/modules/authfack.js';
import { initForm } from './index';
import { validateAllField, validateField } from '@/utils/validate';
import { cleanConfigFormData } from '@/utils/format';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    page: {
        title: 'パルディア社員新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer,
        InputCheckBox,
        InputText,
        Multiselect
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            form: initForm(),
            chatworkTokenShow: '',
            listDataMasterTeam: [],
            listDataMasterGroup: [],
            listDataMasterRoles: [],
            isTeam: true,
            inValidToken: false,
            chatworkTokenTemp: null,
            isWaitToken: false,
            isInputAccountID: false,
            objTemp: {
                chatwork_token: '',
                account_id: null,
                chatwork_id: ''
            },
            isVerifyToken: false,
            hasButtonCheckToken: true,
            phoneSettingType: PHONE_SETTING_EMPLOYEE
        };
    },

    beforeRouteUpdate(to, from, next) {
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
        }
        next();
    },
    mounted() {
        this.getListMasterGroup();
        this.getListMasterRoles();
    },

    methods: {
        checkPermission,
        ...masterMethods,
        ...employeeMethods,
        ...settingPhoneMethods,
        ...authFackMethods,

        resetForm() {
            this.id = null;
            this.form = initForm();
            this.chatworkTokenShow = '';
            this.isInputAccountID = false;
            this.objTemp = {
                chatwork_token: '',
                account_id: null,
                chatwork_id: ''
            };
        },

        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },

        getListMasterRoles() {
            this.listMaster('roles').then((data) => {
                if (!data || data.length <= 0) return;
                this.listDataMasterRoles = data
                    .map((item) => {
                        item.text = nameRoleFixed.find((o) => o.id === item.id)?.text ?? '';
                        return item;
                    })
                    .filter((role) => role.name !== 'partner');
            });
        },

        getListMasterGroup() {
            this.listMaster(keyMaster['employee.department']).then((data) => {
                this.listDataMasterGroup = data;
            });
        },

        getDetail(id) {
            this.loading = true;
            this.detailEmployee(id).then((data) => {
                this.form = {
                    ...this.form,
                    ...data,
                    phone_setting_type: data.phone_setting_type?.map((x) => x.type) ?? [],
                    role: {
                        ...data.role,
                        text: nameRoleFixed.find((o) => o.id === data?.role.id)?.text ?? ''
                    }
                };
                this.objTemp = {
                    chatwork_token: data.chatwork_token,
                    account_id: data.account_id,
                    chatwork_id: data.chatwork_id
                };
                if (data.chatwork_token) {
                    this.chatworkTokenShow = data.chatwork_token;
                    this.isInputAccountID = true;
                } else {
                    this.chatworkTokenShow = '';
                    this.isInputAccountID = false;
                }
                setTimeout(() => {
                    this.setTeam();
                }, 300);
                this.form.configFieldPassword.typesValidate = ['max255', 'password'];
                this.loading = false;
            });
        },

        register() {
            if (this.loading) return;
            this.loading = true;
            if (this.$route.query.id) {
                let dataObject = cleanConfigFormData(this.form);
                dataObject.account_id = dataObject?.account_id ? Number(dataObject.account_id) : null;
                if (dataObject.role) dataObject.role = { id: dataObject.role.id };
                if (dataObject.team) dataObject.team = { id: dataObject.team.id };

                if (!dataObject.password) {
                    delete dataObject.password;
                }
                if (dataObject.chatwork_token === this.chatworkTokenShow) {
                    delete dataObject.chatwork_token;
                }
                if (!this.checkPermiss(['administrator', 'part_time', 'instructor'])) {
                    this.$router.push({ path: '/employees' }).then(() => {
                        showMessage(8080, this.$bvToast, 'アクセス権限がありません。');
                    });
                } else {
                    this.updateEmployee(dataObject).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, 'パルディア社員が更新されました。');
                            this.getDetail(this.$route.query.id);
                            this.form.password = null;
                            if (this.$route.query.id == state.user.id) this.profile();
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                            this.loading = false;
                        }
                    });
                }
            } else {
                if (!this.checkPermiss(['administrator', 'part_time', 'instructor'])) {
                    this.$router.push({ path: '/employees' }).then(() => {
                        showMessage(8080, this.$bvToast, 'パルディア社員が更新されました。');
                    });
                } else {
                    this.registerEmployee(this.form).then((data) => {
                        if (data.code == 200) {
                            this.$router
                                .push({
                                    path: '/employee/view',
                                    query: {
                                        id: data.data.id
                                    }
                                })
                                .then(() => {
                                    showMessage(data.code, this.$bvToast, 'パルディア社員が登録されました。');
                                });
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    });
                }
            }
        },

        formSubmit() {
            this.form.configFieldSelectTeam.typesValidate = this.isTeam ? ['requiredSelect'] : [];
            let check = validateAllField(this.form);
            if (this.isVerifyToken) {
                showMessage(8080, this.$bvToast, this.$t('pageEmployee.verifyToken'));
            }
            if (this.inValidToken || this.isVerifyToken || !check) {
                return;
            } else {
                this.register();
            }
        },
        goToEdit(path, id) {
            this.$router.push({ path: path, query: { id: id } });
        },

        setTeam() {
            this.isTeam = false;
            this.listDataMasterTeam = [];
            let i = this.listDataMasterRoles.findIndex((x) => x.id == this.form.role?.id);
            if (i >= 0) {
                this.form.role.name = this.listDataMasterRoles[i].name;
                if (this.form.role?.name === 'sale') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == process.env.VUE_APP_SALE);
                    if (index >= 0) this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                    this.isTeam = true;
                } else if (this.form.role?.name === 'instructor') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == process.env.VUE_APP_INSTRUCTOR);
                    if (index >= 0) this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                    this.isTeam = true;
                }
            }
        },
        handleChangeSetTeam() {
            this.isTeam = false;
            this.listDataMasterTeam = [];
            this.form.team = null;
            let i = this.listDataMasterRoles.findIndex((x) => x.id == this.form.role?.id);
            if (i >= 0) {
                this.form.role.name = this.listDataMasterRoles[i].name;
                if (this.form.role?.name === 'sale') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == process.env.VUE_APP_SALE);
                    if (index >= 0) this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                    this.isTeam = true;
                } else if (this.form.role?.name === 'instructor') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == process.env.VUE_APP_INSTRUCTOR);
                    if (index >= 0) this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                    this.isTeam = true;
                }
            }
        },

        handleRemoveSetTeam() {
            this.handleValidateField(this.form.configFieldSelectRole);
            this.listDataMasterTeam = [];
            this.form.team = null;
        },

        handleCallChatWork() {
            if ((this.chatworkTokenTemp && this.chatworkTokenTemp === this.form.chatwork_token) || !this.form.chatwork_token) return;
            this.chatworkTokenTemp = this.form.chatwork_token;
            let chatwork_token = this.form.chatwork_token;
            this.isWaitToken = false;
            this.getInfoChatWork({ chatwork_token })
                .then((res) => {
                    this.isWaitToken = false;
                    this.isVerifyToken = false;
                    if (!res) {
                        this.inValidToken = true;
                        this.form.account_id = null;
                        this.form.chatwork_id = '';
                        this.form.configFieldChatWorkToken.error = true;
                        return;
                    }
                    this.inValidToken = false;
                    this.form.account_id = res?.account_id ?? null;
                    this.form.chatwork_id = res?.chatwork_id ?? '';
                    this.objTemp = {
                        account_id: res?.account_id ?? null,
                        chatwork_id: res?.chatwork_id ?? ''
                    };
                })
                .catch((error) => {
                    this.isWaitToken = false;
                    console.log('error', error);
                });
        },

        handleInputChatWorkToken() {
            this.inValidToken = false;
            if (!this.form.chatwork_token) {
                this.isVerifyToken = false;
                this.isInputAccountID = false;
                this.hasButtonCheckToken = true;
                this.form.account_id = null;
                this.form.chatwork_id = '';
            } else {
                this.isInputAccountID = true;
                this.hasButtonCheckToken = false;
                if (this.form.chatwork_token !== this.chatworkTokenTemp) {
                    this.form.account_id = null;
                    this.form.chatwork_id = '';
                    this.isVerifyToken = true;
                } else {
                    this.form.account_id = this.objTemp.account_id;
                    this.form.chatwork_id = this.objTemp.chatwork_id;
                    this.isVerifyToken = false;
                }
            }
        },

        handleValidateField(configField) {
            validateField(configField);
        }
    },
    watch: {
        '$route.query.id': {
            handler: function (val) {
                if (val) {
                    this.getDetail(val);
                }
            },
            immediate: true
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-6">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom01"
                                            >{{ $t('pageEmployee.name') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="name"
                                                :model.sync="form.name"
                                                :config.sync="form.configFieldName"
                                                @blur="handleValidateField(form.configFieldName)"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom02"
                                            >{{ $t('pageEmployee.name_kana') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="kana_name"
                                                :model.sync="form.kana_name"
                                                :config.sync="form.configFieldKanaName"
                                                @blur="handleValidateField(form.configFieldKanaName)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom03"
                                            >{{ $t('pageEmployee.email') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom03"
                                                :model.sync="form.email"
                                                :config.sync="form.configFieldEmail"
                                                @blur="handleValidateField(form.configFieldEmail)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom04"
                                            >{{ $t('pageEmployee.password') }}<span class="text-danger">*</span>
                                        </label>

                                        <div class="col-sm-12">
                                            <InputText
                                                id="password"
                                                :model.sync="form.password"
                                                :config.sync="form.configFieldPassword"
                                                @blur="handleValidateField(form.configFieldPassword)"
                                            />
                                            <div>
                                                <small v-if="this.$route.query.id && !form.configFieldPassword.error" style="font-size: 14px">{{
                                                    $t('notificationCommon.notePass2')
                                                }}</small>
                                                <small v-else-if="!this.$route.query.id && !form.configFieldPassword.error">{{
                                                    $t('notificationCommon.notePass')
                                                }}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployee.cellphoneNumber') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="phone_number"
                                                :model.sync="form.phone_number"
                                                :config.sync="form.configFieldPhoneNumber"
                                                @blur="handleValidateField(form.configFieldPhoneNumber)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="tokenUser">{{ $t('pageEmployee.tokenUser') }}</label>
                                        <div class="col-sm-12">
                                            <div class="d-flex">
                                                <div class="w-100">
                                                    <InputText
                                                        id="chatwork_token"
                                                        :model.sync="form.chatwork_token"
                                                        :config.sync="form.configFieldChatWorkToken"
                                                        @input="handleInputChatWorkToken"
                                                        @blur="handleValidateField(form.configFieldChatWorkToken)"
                                                    />
                                                    <span v-if="inValidToken" class="text-danger">{{ $t('tokenNotExist') }}</span>
                                                </div>
                                                <div class="ml-3">
                                                    <b-button
                                                        :disabled="hasButtonCheckToken"
                                                        variant="success"
                                                        @click="handleCallChatWork"
                                                        :class="{
                                                            disabled_cursor: hasButtonCheckToken
                                                        }"
                                                        style="white-space: nowrap"
                                                        >{{ $t('btn.verify') }}</b-button
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="accountId">{{ $t('pageEmployee.accountId') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="account_id"
                                                :model.sync="form.account_id"
                                                :config.sync="form.configFieldAccountID"
                                                :disabled="isInputAccountID"
                                                @blur="handleValidateField(form.configFieldAccountID)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="chatworkId">{{ $t('pageEmployee.chatworkId') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="chatwork_id"
                                                :model.sync="form.chatwork_id"
                                                :config.sync="form.configFieldChatWorkID"
                                                :disabled="isInputAccountID"
                                                @blur="handleValidateField(form.configFieldChatWorkID)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-2 mw--form d-none d-sm-block" style="padding-right: 0">
                                            <hr />
                                        </div>
                                        <div class="col-sm-12" style="padding-left: 0">
                                            <hr />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom06"
                                            >{{ $t('pageEmployee.role') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-md-6" style="min-width: 220px">
                                            <Multiselect
                                                :id="`select-role_id-object`"
                                                :value.sync="form.role"
                                                :options="listDataMasterRoles"
                                                :config.sync="form.configFieldSelectRole"
                                                @select="handleChangeSetTeam()"
                                                @remove="handleRemoveSetTeam()"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group" v-if="isTeam && form.role?.name !== 'part_time' && form.role?.name !== 'administrator'">
                                        <label class="col-sm-2 mw--form" for="validationCustom07"
                                            >{{ $t('pageEmployee.team') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-md-6" style="min-width: 220px">
                                            <Multiselect
                                                :id="`select-team_id-object`"
                                                :value.sync="form.team"
                                                :options="listDataMasterTeam"
                                                :config.sync="form.configFieldSelectTeam"
                                                @remove="handleValidateField(form.configFieldSelectTeam)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom07">{{ $t('pageEmployee.roleSettingPhone') }}</label>
                                        <div class="col-md-6" style="min-width: 220px">
                                            <div v-for="(stp, k) in phoneSettingType" :key="k">
                                                <InputCheckBox
                                                    :model.sync="form.phone_setting_type"
                                                    :id="`check_phone_setting_type${k}`"
                                                    :value="stp.id"
                                                    :label="stp.label"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom09">{{ $t('pageEmployee.description') }}</label>
                                        <div class="col-sm-12">
                                            <textarea rows="5" id="validationCustom09" v-model="form.note" class="form-control"></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/employees'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermission('employee.register') || (checkPermission('employee.edit') && $route.query.id)"
                            type="submit"
                            @click="formSubmit"
                            class="btn btn-primary ml-3"
                            :disabled="isWaitToken"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style scoped></style>
